import { ThemeProvider } from "@emotion/react";
import {
  createTheme,
  CssBaseline,
  GlobalStyles,
  Snackbar,
  Stack,
} from "@mui/material";
import React, { useState } from "react";

import Header from "./Header";
import Main from "./Main";
import ProgressDialog from "./ProgressDialog";
import { TransferQueueProvider } from "./app/transferQueue";

const globalStyles = (
  <GlobalStyles
    styles={{
      "html, body, #root": {
        height: "100%",
        background: "#101010 !important",
        color: "#ffffff !important"
      },
      ".css-1tt0klo": {
        backgroundColor: "#333333 !important"
      },
      ".css-7jhp7b": {
        color: "#ffffff !important"
      },
      ".css-1jy6qco": {
        backgroundColor: "#101010 !important",
        color: "#ffffff !important"
      },
      ".css-2duac4": {
        color: "#ffffff !important"
      },
      ".css-mbfek": {
        color: "#c1c1c1 !important"
      },
      ".css-1f8bwsm": {
        color: "#ffffffcf !important"
      },
      ".css-1hqopoq": {
        color: "#ffffffcf !important",
        border: "none !important",
        backgroundColor: "#171717 !important"
      },
      ".css-1hqopoq button": {
        color: "#ffffff !important",
      },
      ".css-nbfzex": {
        color: "#c9c9c9 !important",
      },
      ".css-3mf706": {
        color: "#5d5d5d !important",
      },
      ".css-jv9wk0, .css-1em4l5s": {
        color: "#ffffffcf !important",
        backgroundColor: "#171717 !important"
      },
      ".css-uj3p8u": {
        color: "#ffffffb3 !important"
      },
      ".css-13mfrin": {
        color: "#ffffff !important"
      },
      ".css-1xp58ha": {
        color: "#ffffff !important",
        backgroundColor: "#161616 !important",
        borderRadius: "20px"
      },
    }}
  />
);

const theme = createTheme({
  palette: { primary: { main: "#ff1a1a" } },
});

function App() {
  const [search, setSearch] = useState("");
  const [showProgressDialog, setShowProgressDialog] = React.useState(false);
  const [error, setError] = useState<Error | null>(null);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {globalStyles}
      <TransferQueueProvider>
        <Stack sx={{ height: "100%" }}>
          <Header
            search={search}
            onSearchChange={(newSearch: string) => setSearch(newSearch)}
            setShowProgressDialog={setShowProgressDialog}
          />
          <Main search={search} onError={setError} />
        </Stack>
        <Snackbar
          autoHideDuration={5000}
          open={Boolean(error)}
          message={error?.message}
          onClose={() => setError(null)}
        />
        <ProgressDialog
          open={showProgressDialog}
          onClose={() => setShowProgressDialog(false)}
        />
      </TransferQueueProvider>
    </ThemeProvider>
  );
}

export default App;
